import {
  AccessMembershipMutation,
  AccessMembershipMutationVariables,
} from '../__generated__/graphql';

import ACCESS_MEMBERSHIP from '../graphql/accessMembership';
import { MembershipAccessLevelEnum } from '../util/membership/types';
import { RootState } from '../redux/reducers';
import { VirtualMembershipEntity } from '../redux/reducers/@types/MeReduxState';
import { flashApolloError } from '../util/errorUtils';
import useLoadDashboardPreferences from './useLoadDashboardPreferences';
import { useManageLocalActiveMembership } from './useManageLocalActiveMembership';
import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

type AccessMembershipMutationData = AccessMembershipMutation;
type AccessMembershipMutationArgs = AccessMembershipMutationVariables;

type UseAccessMembershipType = {
  accessMembership: (virtualMembershipOption: VirtualMembershipEntity) => void;
};

function generateMembershipEntitiesPropsForDashboardPreferences(
  accessMembership: AccessMembershipMutationData['accessMembership'],
  virtualMembershipEntity?: VirtualMembershipEntity
): { tenantId: string; locationId?: string } {
  const accessMembershipProps = {
    tenantId: accessMembership.tenant.id,
    locationId: accessMembership.location?.id,
  };

  if (!virtualMembershipEntity) {
    return accessMembershipProps;
  }

  // eslint-disable-next-line default-case
  switch (virtualMembershipEntity.virtualId.entityLevel) {
    case MembershipAccessLevelEnum.tenant: {
      return {
        tenantId: virtualMembershipEntity.virtualId.entityId,
        locationId: undefined,
      };
    }

    case MembershipAccessLevelEnum.locationGroup: {
      return {
        tenantId: accessMembership.tenant.id,
        locationId: undefined,
      };
    }

    case MembershipAccessLevelEnum.location: {
      return {
        tenantId: accessMembership.tenant.id,
        locationId: virtualMembershipEntity.virtualId.entityId,
      };
    }

    default:
      return accessMembershipProps;
  }
}

export default function useAccessMembership(): UseAccessMembershipType {
  const { id: userId } = useSelector((state: RootState) => state.me);

  const { loadDashboardPreferences } = useLoadDashboardPreferences();
  const { saveMembershipLocally } = useManageLocalActiveMembership();

  const virtualMembershipOptionRef = useRef<VirtualMembershipEntity | undefined>(undefined);

  const [mutation] = useMutation<AccessMembershipMutationData, AccessMembershipMutationArgs>(
    ACCESS_MEMBERSHIP,
    {
      onCompleted: ({ accessMembership }) => {
        const virtualMembershipOption = virtualMembershipOptionRef.current;
        if (virtualMembershipOption) {
          saveMembershipLocally(virtualMembershipOption);
        }

        if (userId) {
          const membershipEntitiesProps = generateMembershipEntitiesPropsForDashboardPreferences(
            accessMembership,
            virtualMembershipOption
          );

          loadDashboardPreferences({
            ...membershipEntitiesProps,
            userId,
          });
        }
      },
      onError: flashApolloError,
    }
  );

  const accessMembership = (virtualMembershipOption: VirtualMembershipEntity): void => {
    virtualMembershipOptionRef.current = virtualMembershipOption;

    mutation({
      variables: {
        membershipId: virtualMembershipOption.id,
      },
    });
  };

  return {
    accessMembership,
  };
}
