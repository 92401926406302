import { Query, QueryGetDashboardPreferencesArgs } from '../__generated__/graphql';
import { clearPreferences, setPreferences } from '../redux/actions/dashboardPreferences.actions';

import { ApolloError } from '@apollo/client';
import GET_DATE_TIME_PREFERENCES from '../graphql/getDashboardPreferences';
import client from '../client';
import { flashApolloError } from '../util/errorUtils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type GetDashboardPreferencesData = Pick<Query, 'getDashboardPreferences'>;

type UseGetDashboardPreferencesInterfaceType = QueryGetDashboardPreferencesArgs;

interface UseGetDashboardPreferencesInterface {
  loadDashboardPreferences: (props: UseGetDashboardPreferencesInterfaceType) => void;
}

export default function useLoadDashboardPreferences(): UseGetDashboardPreferencesInterface {
  const dispatch = useDispatch();

  const loadDashboardPreferences = useCallback(
    ({ tenantId, locationId, userId }: UseGetDashboardPreferencesInterfaceType): void => {
      client
        .query<GetDashboardPreferencesData, QueryGetDashboardPreferencesArgs>({
          query: GET_DATE_TIME_PREFERENCES,
          fetchPolicy: 'network-only',
          variables: { tenantId, locationId, userId },
        })
        .then((result) => {
          const { getDashboardPreferences } = result.data;

          if (getDashboardPreferences) {
            dispatch(setPreferences({ ...getDashboardPreferences }));
          } else {
            dispatch(clearPreferences());
          }
        })
        .catch((e: ApolloError) => {
          flashApolloError(e);
        });
    },
    []
  );

  return {
    loadDashboardPreferences,
  };
}
