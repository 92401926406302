import { GroupBase } from 'react-select';
import { MembershipAccessLevelEnum } from '../../../../../util/membership/types';
import { MembershipSelectorOptionType } from '../types';
import { VirtualUserMembership } from '../../../../../redux/reducers/@types/MeReduxState';
import { generateVirtualMembership } from '../../../../../util/membership/virtualMembershipUtils';

export type OptionsGroup = GroupBase<MembershipSelectorOptionType>;

export const membershipGroupsLabel = {
  tenant: 'Organization',
  location: 'Locations',
  group: 'Groups',
};

type BasicMembershipForMembershipOption = Pick<
  VirtualUserMembership,
  'id' | 'location' | 'locationGroup' | 'tenant'
>;

type MembershipWithLevel = BasicMembershipForMembershipOption & {
  membershipLevel: MembershipAccessLevelEnum;
};

/** Identifies the membership level */
function getMembershipLevel(
  membership: BasicMembershipForMembershipOption
): MembershipAccessLevelEnum {
  if (membership.location) {
    return MembershipAccessLevelEnum.location;
  }
  if (membership.locationGroup) {
    return MembershipAccessLevelEnum.locationGroup;
  }

  return MembershipAccessLevelEnum.tenant;
}

/** Generates a Membership Option based on a specific Virtual Membership
 * (with the Membership level) */
function generateMembershipOptionWithLevel(
  membership: BasicMembershipForMembershipOption,
  level: MembershipAccessLevelEnum
): MembershipSelectorOptionType {
  const virtualMembership = generateVirtualMembership(membership);

  return {
    value: JSON.stringify(virtualMembership.virtualId),
    label: membership[level]?.name ?? '',
    virtualMembership,
  };
}

/** Generates a Membership Option based on a specific Virtual Membership
 * (without the Membership Level) */
export function generateMembershipOptionWithoutLevel(
  membership: BasicMembershipForMembershipOption
): MembershipSelectorOptionType {
  const level = getMembershipLevel(membership);

  return generateMembershipOptionWithLevel(membership, level);
}

/** Receives the full list of virtual membershios, then:
 * - Filter per level
 * - Transform the membership into a Membership Option
 * - Sort the memberships alphabetically */
const generateMembershipOptionsPerLevel = (
  memberships: MembershipWithLevel[],
  level: MembershipAccessLevelEnum
): MembershipSelectorOptionType[] => {
  if (!level) {
    return [];
  }

  return memberships
    .filter((membership) => membership.membershipLevel === level)
    .map((membership) => generateMembershipOptionWithLevel(membership, membership.membershipLevel))
    .sort((mA, mB) => mA.label.localeCompare(mB.label));
};

/** Generates the Membership Options list based on a list of virtual memberships */
export default function generateMembershipOptions(
  memberships: BasicMembershipForMembershipOption[]
): OptionsGroup[] {
  const membershipsWithLevel = memberships.map(
    (membership): MembershipWithLevel => {
      const membershipLevel = getMembershipLevel(membership);

      return {
        ...membership,
        membershipLevel,
      };
    }
  );

  const tenantOptions: OptionsGroup = {
    label: membershipGroupsLabel.tenant,
    options: generateMembershipOptionsPerLevel(
      membershipsWithLevel,
      MembershipAccessLevelEnum.tenant
    ),
  };

  const locationOptions: OptionsGroup = {
    label: membershipGroupsLabel.location,
    options: generateMembershipOptionsPerLevel(
      membershipsWithLevel,
      MembershipAccessLevelEnum.location
    ),
  };

  const groupOptions: OptionsGroup = {
    label: membershipGroupsLabel.group,
    options: generateMembershipOptionsPerLevel(
      membershipsWithLevel,
      MembershipAccessLevelEnum.locationGroup
    ),
  };

  return [tenantOptions, locationOptions, groupOptions];
}
